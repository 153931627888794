import '@fortawesome/fontawesome-free/css/all.css';

window.onload = () => {
    const burgerMenu = document.getElementById('burgerMenu');
    const headerList = document.getElementById('headerList');

    console.log(burgerMenu);
    burgerMenu.addEventListener('click',()=> {
        console.log(headerList.style.right)
       headerList.style.right = headerList.style.right == '0px' ? '1000vh' : '0px';
    })
    
    

}

